import { WindowLocation } from "@reach/router";
import { graphql } from "gatsby";
import React from "react";
import Breadcrumbs from "../components/Breadcrumbs/Breadcrumbs";
import GeneralContentLayout from "../layouts/GeneralContentLayout/GeneralContentLayout";
import PageLayout from "../layouts/PageLayout/PageLayout";
import { CommonContext } from "../model/common";
import { SanityDybWinners } from "../model/dyb";
import { GlobalMenus } from "../model/menu";
import { SiteArea } from "../utils/analytics";

export const pageQuery = graphql`
  query DybWinndersPage($_id: String!, $navLanguage: String) {
    menus: sanityGlobalConfig(language: { eq: $navLanguage }) {
      ...MenuData
    }
    page: sanityDybWinners(_id: { eq: $_id }) {
      _id
      _type
      title
      metaDescription
      socialTitle
      socialDescription
      socialImage {
        ...SanityImage
      }
      metaDescription
      _rawContent(resolveReferences: { maxDepth: 5 })
      translationSponsors {
        ...TranslationSponsors
      }
      licence {
        ...Licence
      }
    }
  }
`;

const DybWinnersPage = ({
  pageContext,
  data: { menus, page },
  location
}: DybWinnersPageProps) => {
  const { title } = page;
  return (
    <PageLayout
      siteArea={SiteArea.DO_YOUR_BIT}
      metadata={{
        title,
        page,
        alternates: pageContext.alternates,
        type: "article"
      }}
      menus={menus}
      strings={pageContext.strings}
      location={location}
    >
      <GeneralContentLayout
        article={page}
        related={
          [] /* Unused for now, in future could do last 3 past years excluding this? */
        }
        relatedTitleId="related-stories"
        breadcrumbs={
          <Breadcrumbs
            breadcrumbs={[
              { title: "do your :bit", href: "../.." },
              { title, href: "./" }
            ]}
          />
        }
      />
    </PageLayout>
  );
};

export default DybWinnersPage;

interface DybWinnersPageProps {
  data: {
    menus: GlobalMenus;
    page: SanityDybWinners;
  };
  location: WindowLocation;
  pageContext: CommonContext;
}
